import React, { Fragment } from "react";

const Home = () => (
    <Fragment>
        <div className="text-center hero my-5">
            <h1 className="mb-4">React.js Sample Project</h1>

            <p className="lead">
                This is a sample application that demonstrates an authentication flow for an SPA, using <a href="https://reactjs.org">React.js</a>
            </p>
        </div>

        <hr />
    </Fragment>
);

export default Home;
