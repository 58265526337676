import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

// Create a function which takes a single object as it's parameter
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
    // Declare state variables
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Only run once, never gets re-run on re-render (since we pass empty array as 2nd arg)
        const initAuth0 = async () => {
            // Inside the initOptions object, likely passing in domain, client_id, redirect_uri
            // Docs say mandatory args are domain, client_id
            // If  you don't provide redirect_uri (which we do), you must provide it in other method that provide auth
            const auth0FromHook = await createAuth0Client(initOptions);
            // Set the Auth0 client in our state
            setAuth0(auth0FromHook);

            // Check for the "code" parameter (but why ?)
            if (window.location.search.includes("code=")) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback(appState);
            }

            // Obtain state of current authentication, and set as state variable
            const isAuthenticated = await auth0FromHook.isAuthenticated();
            setIsAuthenticated(isAuthenticated);

            // If we happen to be authenticated, get the user info (e.g. email address)
            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                setUser(user);
            }

            // Tell the app that loading is complete (meaning that we know our authentication state)
            setLoading(false);
        };
        initAuth0();
        // eslint-disable-next-line
    }, []);

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (...p) => auth0Client.logout(...p)
            }}>
            {children}
        </Auth0Context.Provider>
    );
};
