import React, { Fragment } from "react";

const Page02 = () => (
    <Fragment>
        <div className="text-center hero my-5">
            <h1 className="mb-4">Page02</h1>
            <p className="lead">This is Page02</p>
        </div>

        <hr />
    </Fragment>
);

export default Page02;
