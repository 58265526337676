import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Page01 from "./views/Page01";
import Page02 from "./views/Page02";
import Profile from "./views/Profile";
import { useAuth0 } from "./react-auth0-spa";

// createBrowserHistory
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
    const { loading } = useAuth0();
    console.log("loading:", loading);
    if (loading) {
        return <Loading />;
    }

    return (
        <Router history={history}>
            <div id="app" className="d-flex flex-column h-100">
                <NavBar />
                <Container className="flex-grow-1 mt-5">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/page01" exact component={Page01} />
                        <PrivateRoute path="/page02" exact component={Page02} />
                        <PrivateRoute path="/profile" component={Profile} />
                    </Switch>
                </Container>
            </div>
        </Router>
    );
};

export default App;
