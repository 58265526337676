import React, { Fragment } from "react";

const Page01 = () => (
    <Fragment>
        <div className="text-center hero my-5">
            <h1 className="mb-4">Page01</h1>
            <p className="lead">This is Page01</p>
        </div>

        <hr />
    </Fragment>
);

export default Page01;
